import React, { createContext } from "react";

import { useDispatch } from "react-redux";
import * as actions from "../store/actions";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
// Add the Firebase products that you want to use
import {
  getAuth,
  connectAuthEmulator,
  signInWithRedirect,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  updateProfile,
} from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  updateDoc,
  onSnapshot,
  getDoc,
  deleteDoc,
  addDoc,
  collection,
  setDoc,
  getDocs,
  query,
  where,
  Timestamp,
  runTransaction,
  arrayUnion,
  disableNetwork,
  initializeFirestore,
} from "firebase/firestore";
import {
  getStorage,
  connectStorageEmulator,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

import { firebaseConfig } from "./firebaseConfig";
import { INTRO } from "../constants/routes";
import { history } from "../store/history";
import axios from "axios";
import { readFileAsBinaryString } from "../Helpers/Document";

const FirebaseContext = createContext(null);

export { FirebaseContext };

const FirebaseProvider = ({ children }) => {
  let firebaseObj = {
    app: null,
    db: null,
    auth: null,
    storage: null,
    functions: null,
    performance: null,
    api: null,
  };

  const dispatch = useDispatch();

  const isLocalhost = window.location.hostname === "localhost";

  /*
   * Check if firebase app has been initialized previously
   * if not, initialize with the config we saved earlier
   */

  const app = initializeApp(firebaseConfig);

  window.fbInstance = app;

  // Initializing firebase products
  const db = initializeFirestore(app, {
    cacheSizeBytes: 1048576, // 1MB cache size
    experimentalForceLongPolling: true, // Example of enabling long polling
    ignoreUndefinedProperties: true, // Ignore undefined properties in Firestore documents
  });
  const auth = getAuth(app);
  auth.languageCode = "pt-BR";
  const functions = getFunctions(app);
  const storage = getStorage(app);
  var performance = null;
  var analytics = null;

  if (!isLocalhost) {
    performance = getPerformance(app);
    analytics = getAnalytics(app);
  } else {
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://localhost:9099/");
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", 9199);
  }

  if (process.env.NODE_ENV === "test") {
    // Disable persistence if needed (for example, in tests)
    disableNetwork(db);
  }

  firebaseObj = {
    app,
    db,
    auth,
    storage,
    functions,
    performance,
    analytics,
    api: {
      signOut: handleSignOut,
      signInWithProvider,
      linkWithCredential: handleLinkWithCredential,
      fetchData,
      getService,
      getRequest,
      getStep,
      getTask,
      getOldDelivery,
      getDelivery,
      getAutomation,
      getExamplePage,
      getExampleStep,
      getExampleTask,
      getExampleDelivery,
      getActionPlan,
      getBudget,
      getStepInAP,
      getTaskInAP,
      getContracts,
      getDataList,
      getSignature,
      getDocumentInsideSignature,
      getCustomAutomation,
      getCustomAutomationsList,
      getCustomServices,
      getPartTypes,
      getCards,
      addFeedback,
      addPart,
      setPart,
      updatePart,
      deletePart,
      addAvatar,
      addAutomation,
      addSharedDocument,
      updateSharedDocument,
      storeFilesInStorage,
      getDownloadURL: handleGetDownloadURL,
      getUserPicture,
      updateRequestFiles,
      saveError,
      deleteSignatureSession,
      getPart,
      getShareSteps,
      getPromoCode,
      requestWasViewed,
      taskWasViewed,
      deliveryWasViewed,
      automationWasViewed,
      signatureWasViewed,
      documentInsideSignatureWasViewed,
      getSharedDocument,
      sharedDocumentWasViewed,
      mailAutomationSugestionToIT,
      mailFeedbackToIT,
      sendOnlyMailToIT,
      mailServiceToIT,
      mailSharedDocument,
      mailSignToken,
      mailNewClasses,
      addAutomationData,
      updateAutomationData,
      deleteAutomationData,
      updateUser,
      updateUserProfile,
      userSawIntro,
      userSawNewRelease,
      updateUserInSharedDocument,
      requestAccountDeletion,
      convertToPDF,
    },
  };

  // Authentication methods
  function handleSignOut() {
    history.push(INTRO);
    dispatch(actions.signOut());
    return signOut(auth);
  }

  function signInWithProvider(type) {
    var provider;
    if (type === "google") {
      provider = new GoogleAuthProvider();
    } else if (type === "facebook") {
      provider = new FacebookAuthProvider();
    }
    return signInWithRedirect(auth, provider);
  }

  function handleLinkWithCredential(email, password) {
    var credential = EmailAuthProvider.credential(email, password);
    return linkWithCredential(auth.currentUser, credential);
  }

  // Realtime listeners with dispatch

  function fetchData(user) {
    const { uid } = user;
    getUserToRedux(user);
    getUserCards(uid);
    getUserTransactions(uid);
    getDataList(uid);
    getExampleCards();
    getServicesList();
    getPartColors();
    getTutorials();
    getMentoringLevels();
    getMentoringLessons();
    getMentoringBenefits();
    getMentoringBadges();
    getCustomAutomations(uid);
    getCustomServices(uid);
  }

  function getUserToRedux(user) {
    const { uid, displayName, email } = user;
    const userRef = doc(db, "users", uid);
    onSnapshot(userRef, async (snapshot) => {
      if (!snapshot.exists()) {
        dispatch(
          actions.setUser({
            uid,
            isAnon: true,
            credits: 0,
            signUpDate: Timestamp.fromDate(new Date()),
          })
        );
        return;
      }
      const userData = snapshot.data();
      let avatar;
      if (userData.picturePath) {
        avatar = await getUserPicture(userData.picturePath).catch((error) => {
          console.error("Error getting user picture", error);
        });
      }

      if (userData.notAcceptedTerms) {
        dispatch(actions.setAcceptTermsDialog(true));
      } else {
        dispatch(actions.setAcceptTermsDialog(false));
      }

      let emailVerified = user.emailVerified;
      if (userData.emailVerified === true) {
        emailVerified = true;
      }
      dispatch(
        actions.setUser({
          ...userData,
          uid,
          emailVerified,
          avatar,
        })
      );
    });
  }

  function getUserCards(uid) {
    const cardsRef = doc(db, "users", uid, "history", "cards");
    onSnapshot(cardsRef, function (snapshot) {
      if (!snapshot.exists()) return;
      const sortedArray = snapshot.data().cardsArray.sort((a, b) => {
        const bDate = b.date || b.createdAt;
        const aDate = a.date || a.createdAt;
        return bDate - aDate;
      });
      dispatch(actions.setUserCards(sortedArray));
    });
  }

  function getUserTransactions(uid) {
    const transactionsRef = doc(db, "users", uid, "wallet", "transactions");

    onSnapshot(transactionsRef, (snapshot) => {
      if (!snapshot.exists()) return;
      const sortedArray = snapshot
        .data()
        .transactionsArray.sort((a, b) => b.date - a.date);
      dispatch(actions.setUserTransactions(sortedArray));
    });
  }

  function getDataList(uid) {
    const dataListRef = doc(db, "users", uid, "dataList", "dataList");

    onSnapshot(dataListRef, (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(actions.setDataList(snapshot.data().dataArray));
    });
  }

  function getExampleCards() {
    const exampleCardsRef = doc(db, "exampleCards", "exampleCards");

    onSnapshot(exampleCardsRef, function (snapshot) {
      if (!snapshot.exists()) return;
      dispatch(actions.setExampleCards(snapshot.data().exampleCards));
    });
  }

  function getServicesList() {
    const servicesListRef = doc(db, "servicesList", "servicesList");

    onSnapshot(servicesListRef, function (snapshot) {
      if (!snapshot.exists()) return;
      dispatch(
        actions.setServicesList(
          snapshot
            .data()
            .servicesListArray.filter((service) => service.isFor !== "lawyer")
        )
      );
    });
  }

  function getPartColors() {
    const partColorsRef = doc(db, "partColors", "partColorsDoc");

    onSnapshot(partColorsRef, (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(actions.setPartColors(snapshot.data().partColorsArray));
    });
  }

  function getTutorials() {
    const tutorialsRef = doc(db, "tutorials", "tutorialsDoc");

    onSnapshot(tutorialsRef, (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(actions.setTutorials(snapshot.data().tutorialsArray));
    });
  }

  function getCustomAutomations(uid) {
    const customAutomationsRef = doc(
      db,
      "users",
      uid,
      "customAutomations",
      "customAutomationsDoc"
    );

    getDoc(customAutomationsRef)
      .then((doc) => {
        if (!doc.exists()) return;
        dispatch(actions.setCustomAutomations(doc.data().array));
      })
      .catch((error) => {
        console.error("Error getting mentoring levels ", error);
      });
  }

  function getCustomServices(uid) {
    const customServicesRef = doc(
      db,
      "users",
      uid,
      "customServices",
      "customServicesDoc"
    );

    getDoc(customServicesRef)
      .then((doc) => {
        if (!doc.exists()) return;
        dispatch(actions.setCustomServices(doc.data().array));
      })
      .catch((error) => {
        console.error("Error getting mentoring levels ", error);
      });
  }

  // Unique getters

  function getService(serviceId) {
    const ref = doc(db, "servicesList", "servicesList", "services", serviceId);

    return getDoc(ref);
  }

  function getRequest(uid, requestId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId
    );
    return getDoc(ref);
  }

  function getStep(uid, requestId, stepId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId
    );
    return getDoc(ref);
  }

  function getTask(uid, requestId, stepId, taskId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId,
      "tasks",
      taskId
    );

    return getDoc(ref);
  }

  function getOldDelivery(uid, requestId, stepId, deliveryId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId,
      "deliveries",
      deliveryId
    );

    return getDoc(ref);
  }

  function getDelivery(uid, requestId, stepId, taskId, deliveryId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId,
      "tasks",
      taskId,
      "deliveries",
      deliveryId
    );
    return getDoc(ref);
  }

  function getExamplePage(requestId) {
    const ref = doc(
      db,
      "exampleCards",
      "exampleCards",
      "examplePages",
      requestId
    );
    return getDoc(ref);
  }

  function getExampleStep(requestId, stepId) {
    const ref = doc(
      db,
      "exampleCards",
      "exampleCards",
      "examplePages",
      requestId,
      "exampleSteps",
      stepId
    );

    return getDoc(ref);
  }

  function getExampleTask(requestId, stepId, taskId) {
    const ref = doc(
      db,
      "exampleCards",
      "exampleCards",
      "examplePages",
      requestId,
      "exampleSteps",
      stepId,
      "exampleTasks",
      taskId
    );
    return getDoc(ref);
  }

  function getExampleDelivery(requestId, stepId, deliveryId) {
    const ref = doc(
      db,
      "exampleCards",
      "exampleCards",
      "examplePages",
      requestId,
      "exampleSteps",
      stepId,
      "exampleDeliveries",
      deliveryId
    );
    return getDoc(ref);
  }

  function getActionPlan(uid, actionPlanId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "actionPlans",
      actionPlanId
    );
    return getDoc(ref);
  }

  function getBudget(uid, actionPlanId, budgetId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "actionPlans",
      actionPlanId,
      "budgets",
      budgetId
    );
    return getDoc(ref);
  }

  function getStepInAP(uid, actionPlanId, stepId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "actionPlans",
      actionPlanId,
      "steps",
      stepId
    );
    return getDoc(ref);
  }

  function getTaskInAP(uid, actionPlanId, stepId, taskId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "actionPlans",
      actionPlanId,
      "steps",
      stepId,
      "tasks",
      taskId
    );
    return getDoc(ref);
  }

  function getContracts() {
    const ref = doc(db, "contractTypes", "contractTypes");
    return getDoc(ref);
  }

  function getPart(uid, partId) {
    const ref = doc(db, "users", uid, "dataList", "dataList", "parts", partId);
    return getDoc(ref);
  }

  function getAutomation(uid, id) {
    const ref = doc(db, "users", uid, "automations", id);

    return getDoc(ref);
  }

  function getSignature(uid, id) {
    const ref = doc(db, "users", uid, "signatures", id);

    return getDoc(ref);
  }

  function getDocumentInsideSignature(uid, signatureId, id) {
    const ref = doc(
      db,
      "users",
      uid,
      "signatures",
      signatureId,
      "documents",
      id
    );

    return getDoc(ref);
  }

  function getCustomAutomation(uid, id) {
    const ref = doc(
      db,
      "users",
      uid,
      "customAutomationsList",
      "customAutomationsList",
      "customAutomation",
      id
    );

    return getDoc(ref);
  }

  function getShareSteps(type) {
    const ref = doc(db, "shareSteps", type);
    return getDoc(ref);
  }

  function getSharedDocument(id) {
    const ref = doc(db, "sharedDocuments", id);
    return getDoc(ref);
  }

  // Getters for BIG documents

  function getPartTypes() {
    const ref = doc(db, "partTypes", "partTypesDoc");
    return getDoc(ref);
  }

  function getCustomAutomationsList(uid) {
    const ref = doc(
      db,
      "users",
      uid,
      "customAutomationsList",
      "customAutomationsList"
    );
    return getDoc(ref);
  }

  function getMentoringLevels() {
    const ref = doc(db, "mentoring", "levels");
    getDoc(ref)
      .then((doc) => {
        if (!doc.exists()) return;
        dispatch(actions.setMentoringLevels(doc.data().array));
      })
      .catch((error) => {
        console.error("Error getting mentoring levels ", error);
      });
  }

  function getMentoringLessons() {
    const lessonsRef = doc(db, "mentoring", "lessons");

    getDoc(lessonsRef)
      .then(async (doc) => {
        if (!doc.exists()) return;
        var newLessons = [];
        for (const lesson of doc.data().array) {
          var newItems = [];
          for (const item of lesson.items) {
            if (item.type === "video") {
              const url = await getDownloadURL(
                ref(storage, `mentoring_videos/${item.videoName}.mp4`)
              );
              newItems.push({ ...item, videoUrl: url });
            } else {
              newItems.push(item);
            }
          }
          newLessons.push({ ...lesson, items: newItems });
        }
        dispatch(actions.setMentoringLessons(newLessons));
      })
      .catch((error) => {
        console.error("Error getting mentoring lessons ", error);
      });
  }

  function getMentoringBadges() {
    const ref = doc(db, "mentoring", "badges");

    getDoc(ref)
      .then((doc) => {
        if (!doc.exists()) return;
        dispatch(actions.setMentoringBadges(doc.data().array));
      })
      .catch((error) => {
        console.error("Error getting mentoring badges ", error);
      });
  }

  function getMentoringBenefits() {
    const ref = doc(db, "mentoring", "benefits");

    getDoc(ref)
      .then((doc) => {
        if (!doc.exists()) return;
        dispatch(actions.setMentoringBenefits(doc.data().array));
      })
      .catch((error) => {
        console.error("Error getting mentoring benefits ", error);
      });
  }

  // Cards

  function getCards(uid) {
    const ref = doc(db, "users", uid, "wallet", "cards");
    return getDoc(ref);
  }

  // App functionalities

  function requestWasViewed(uid, requestId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId
    );
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function taskWasViewed(uid, requestId, stepId, taskId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId,
      "tasks",
      taskId
    );
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function deliveryWasViewed(uid, requestId, stepId, taskId, deliveryId) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId,
      "steps",
      stepId,
      "tasks",
      taskId,
      "deliveries",
      deliveryId
    );
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function automationWasViewed(uid, id) {
    const ref = doc(db, "users", uid, "automations", id);
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function signatureWasViewed(uid, id) {
    const ref = doc(db, "users", uid, "signatures", id);
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function documentInsideSignatureWasViewed(uid, signatureId, id) {
    const ref = doc(
      db,
      "users",
      uid,
      "signatures",
      signatureId,
      "documents",
      id
    );
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function sharedDocumentWasViewed(id) {
    const ref = doc(db, "sharedDocuments", id);
    updateDoc(ref, {
      wasViewed: true,
    });
  }

  function deleteSignatureSession(uid, sessionId) {
    const ref = doc(db, "users", uid, "signatureSessions", sessionId);
    return deleteDoc(ref);
  }

  function addFeedback(value, message, date) {
    const uid = auth.currentUser.uid;
    const ref = collection(db, "users", uid, "feedbacks");
    addDoc(ref, {
      value: value,
      message: message,
      date: date,
    });
  }

  function addPart(part) {
    const { uid } = auth.currentUser;
    const ref = collection(db, "users", uid, "dataList", "dataList", "parts");

    return addDoc(ref, part);
  }

  function setPart(id, part) {
    const { uid } = auth.currentUser;
    const ref = doc(db, "users", uid, "dataList", "dataList", "parts", id);

    return setDoc(ref, part);
  }

  function updatePart(id, part) {
    const { uid } = auth.currentUser;
    const ref = doc(db, "users", uid, "dataList", "dataList", "parts", id);

    return updateDoc(ref, part);
  }

  function deletePart(id) {
    const { uid } = auth.currentUser;
    const ref = doc(db, "users", uid, "dataList", "dataList", "parts", id);

    return deleteDoc(ref);
  }

  function addAutomation(uid, automation) {
    const ref = doc(db, "users", uid, "automations", automation.id);
    return setDoc(ref, automation);
  }

  function addSharedDocument(id, automation) {
    const ref = doc(db, "sharedDocuments", id);

    return setDoc(ref, automation);
  }
  function updateSharedDocument(id, automation) {
    const ref = doc(db, "sharedDocuments", id);

    return updateDoc(ref, automation);
  }

  function getPromoCode(value) {
    const q = query(collection(db, "promoCodes"), where("code", "==", value));
    return getDocs(q);
  }

  function addAvatar(url) {
    const uid = auth.currentUser.uid;

    // Get file name from url.
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("load", transferComplete);
    xhr.addEventListener("error", transferFailed);
    xhr.addEventListener("abort", transferCanceled);

    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status === 200) {
        var reader = new FileReader();
        reader.onload = function (e) {
          const storageRef = ref(storage, `clients_picture/${uid}.jpg`);

          var metadata = {
            contentType: "image/jpeg",
          };

          var base64result = reader.result.split(",")[1];
          var blob = b64toBlob(base64result);

          var uploadTask = uploadBytes(storageRef, blob, metadata);

          uploadTask.on(
            "state_changed",
            null,
            function (error) {
              console.error("Upload failed:", error);
            },
            function () {
              const ref = doc(db, "users", uid);
              updateDoc(ref, {
                picturePath: `${uploadTask.snapshot.metadata.name}`,
              }).then(function () {
                console.log("Document successfully updated!");
              });
            }
          );
        };
        reader.readAsDataURL(this.response);
      }
    };

    xhr.open("GET", url);
    xhr.send();
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function transferComplete(evt) {
    window.onload = function () {
      // Sign the user in anonymously since accessing Storage requires the user to be authorized.
      auth
        .signInAnonymously()
        .then(function (user) {
          document.getElementById("file").disabled = false;
        })
        .catch(function (error) {
          console.error("Anonymous Sign In Error", error);
        });
    };
  }

  function transferFailed(evt) {
    console.error("An error occurred while transferring the file.");
  }

  function transferCanceled(evt) {
    console.log("The transfer has been canceled by the user.");
  }

  async function storeFilesInStorage(uid, requestId, files) {
    if (!uid || !requestId || !files || files.length === 0) {
      throw new Error("Invalid parameters.");
    }

    // Store promises for all file uploads
    const uploadPromises = files.map(async (file) => {
      try {
        let fileBlob = file;
        let fileName = file.name;
        const isDocx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        // Convert DOCX to PDF before upload
        if (isDocx) {
          fileBlob = await convertToPDF(file);
          if (!fileBlob) throw new Error("Error converting document to PDF.");
          fileName = file.name.replace(".docx", ".pdf");
        }

        // Upload file to Firebase Storage
        const fileRef = ref(
          storage,
          `users_docs/${uid}/${requestId}/${fileName}`
        );
        const snapshot = await uploadBytes(fileRef, fileBlob);

        // Get download URL
        const downloadURL = await getDownloadURL(snapshot.ref);

        return { name: fileName, downloadURL };
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        return null; // Skip failed file instead of stopping the loop
      }
    });

    // Wait for all uploads to complete
    const results = await Promise.all(uploadPromises);

    // Remove any failed file uploads from the result
    return results.filter((file) => file !== null);
  }

  async function convertToPDF(file) {
    try {
      if (!file) throw new Error("No file provided.");

      // Convert file to base64
      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Get base64 string without data URL prefix
          const base64 = reader.result.split(",")[1];
          resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      const convertToPDF = httpsCallable(functions, "document-convertToPDF");

      // Send the file to the Cloud Run API
      const response = await convertToPDF({
        file: base64String,
        fileName: file.name,
        fileType: file.type,
      });
      console.log("Conversion response:", response);

      // Convert response to a Blob (PDF)
      return new Blob([response.data], { type: "application/pdf" });
    } catch (error) {
      if (error.response) {
        // The request was made, but the server responded with a status code
        console.error(
          "Conversion Error:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No Response (Network Error):", error.request);
      } else {
        // Something else went wrong
        console.error("Error:", error.message);
      }
      throw new Error("Failed to convert document to PDF.");
    }
  }

  function getUserPicture(pictureName) {
    return getDownloadURL(ref(storage, `clients_picture/${pictureName}`));
  }

  function handleGetDownloadURL(uid, requestId, file) {
    return getDownloadURL(
      ref(storage, `users_docs/${uid}/${requestId}/${file.name}`)
    );
  }

  function updateRequestFiles(uid, requestId, files) {
    const ref = doc(
      db,
      "users",
      uid,
      "history",
      "cards",
      "requests",
      requestId
    );
    return updateDoc(ref, {
      files: arrayUnion(...files),
    });
  }

  function addAutomationData(data) {
    const { uid } = auth.currentUser;
    const ref = collection(
      db,
      "users",
      uid,
      "dataList",
      "automationList",
      "automations"
    );
    return addDoc(ref, data);
  }

  function updateAutomationData(id, data) {
    const { uid } = auth.currentUser;
    const ref = doc(
      db,
      "users",
      uid,
      "dataList",
      "automationList",
      "automations",
      id
    );
    return updateDoc(ref, data);
  }

  function deleteAutomationData(id) {
    const { uid } = auth.currentUser;
    const ref = doc(
      db,
      "users",
      uid,
      "dataList",
      "automationList",
      "automations",
      id
    );
    return deleteDoc(ref);
  }

  function updateUser(obj) {
    const { uid } = auth.currentUser;
    const ref = doc(db, "users", uid);
    return updateDoc(ref, obj);
  }
  function updateUserProfile(obj) {
    return updateProfile(auth.currentUser, obj);
  }

  async function updateUserInSharedDocument(data) {
    const { documentId, sessionId } = data;

    const sharedDocumentRef = doc(db, "sharedDocuments", documentId);

    try {
      await runTransaction(db, async (transaction) => {
        const sharedDocumentDoc = await transaction.get(sharedDocumentRef);
        if (!sharedDocumentDoc.exists()) {
          throw "Document does not exist!";
        }

        const data = sharedDocumentDoc.data();
        const newSteps = data.steps.map((s) => {
          if (s.sessionId === sessionId) {
            return { ...s, uid: auth.currentUser.uid };
          }
          return s;
        });
        if (data.users) {
          const newUsers = data.users.map((u) => {
            if (u.sessionId === sessionId) {
              return {
                ...u,
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
                picturePath: "",
              };
            }
            return u;
          });

          transaction.update(sharedDocumentRef, {
            users: newUsers,
            steps: newSteps,
          });
        } else {
          const updatedSigners = data.signers.map((s) => {
            if (s.sessionId === sessionId) {
              return {
                ...s,
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
                picturePath: "",
              };
            }
            return s;
          });

          transaction.update(sharedDocumentRef, {
            signers: updatedSigners,
            steps: newSteps,
          });
        }
      });
      console.log("Update shared document success");
    } catch (e) {
      console.error("Update shared document failed: ", e);
    }
  }

  function userSawIntro() {
    const ref = doc(db, `users/${auth.currentUser.uid}`);
    updateDoc(ref, { sawIntro: true }).catch((error) => {
      console.error("Couldn't update value", error);
    });
  }

  function userSawNewRelease(release) {
    const ref = doc(db, `users/${auth.currentUser.uid}`);
    updateDoc(ref, {
      newReleases: arrayUnion({ ...release, seen: true }),
    }).catch((error) => {
      console.error("Couldn't update value", error);
    });
  }

  function saveError(error) {
    const ref = collection(db, "users", auth.currentUser.uid, "errors");

    addDoc(ref, {
      message: error.message,
      page: window.location.pathname,
      createdAt: new Date(),
    })
      .then(() => {
        console.log("Error saved with success!");
      })
      .catch((e) => {
        console.error("Error saving error:", e);
      });
  }

  const mailTo = isLocalhost
    ? "paulo@sociilaw.com"
    : "daniel@sociilaw.com, paulo@sociilaw.com, fernanda@sociilaw.com, contato@sociilaw.com";

  function mailAutomationSugestionToIT({ displayName, email }, sugestion) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: mailTo,
        subject: `Automação sugerida por ${displayName || "Anônimo"}`,
        html: `<p>E-mail: <b>${email || "Usuário anônimo"}</b></p>
               <p>Sugestão de automação: <b>${sugestion}</b></p>`,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function mailFeedbackToIT({ displayName, email }, value, message) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: mailTo + ", alexsandro@sociilaw.com",
        subject: `Feedback feito por ${displayName}`,
        html: `
          <p>E-mail: ${email}</p>
          <p>NPS: ${value}
          <p>Mensagem: ${message}</p>
        `,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function sendOnlyMailToIT(
    { displayName, email },
    message,
    { requestId, stepId, taskId, title }
  ) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: mailTo,
        subject: `Mensagem enviada por ${displayName}`,
        html: `<p>E-mail: <b>${email}</b></p>
               <p>Id da requisição: <b>${requestId}</b></p>
               ${stepId ? `<p>Id da etapa: <b>${stepId}</b></p>` : ""}
               ${taskId ? `<p>Id da tarefa: <b>${taskId}</b></p>` : ""}
               <p>Título: <b>${title}</b></p>
               <p>Mensagem: <b>${message}</b></p>`,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function mailServiceToIT({ displayName, email, phone }, service, brand) {
    const brandHTML = brand
      ? `
          <p>Nome da marca: <b>${brand.name}</b></p>
          <p>Área de atuação da marca: <b>${brand.class}</b></p>
        `
      : "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: mailTo,
        subject: `Serviço requisitado por ${displayName}`,
        html: `
          <br/>
          <p>Título do serviço: <b>${service.title}</b></p>
          ${brandHTML}
          <br/>
          <h4>Dados do usuário</h4>
          <p>Nome: <b>${displayName}</b></p>
          <p>E-mail: <b>${email}</b></p>
          <p>Telefone: <b>${phone}</b></p>
        `,
      }),
    };

    return fetch(
      isLocalhost
        ? "http://127.0.0.1:5001/appm2law/us-central1/mail-sendMail"
        : "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function mailSharedDocument(
    { email, type, name },
    title,
    creatorName,
    docType,
    id,
    id2
  ) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: `${creatorName} chamou você para preencher uma ${title} na Socii`,
        html: `
        <div
          style="background-color: #ffeee6; font-family: 'Roboto Flex', sans-serif"
        >
          <section style="background-color: #ff6600; padding: 1rem; display: flex">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/appm2law.appspot.com/o/email_imgs%2Flogo.png?alt=media&token=70489f56-8205-408d-aebf-e8b1a542e88d"
              width="200px"
              style="margin: 0 auto"
            />
          </section>
          <section style="background-color: #ff9966; padding: 0.95rem 0">
            <p
              style="
                color: #fff;
                font-size: 1.8rem;
                text-align: center;
                margin: 0;
                font-family: 'Bodoni Moda', serif;
              "
            >
              ${docType.toUpperCase()}
            </p>
          </section>
          <section style="max-width: 600px; margin: 0 auto; padding: 2rem">
            <div style="padding: 0 1.5rem">
              <p
                style="
                  font-size: 1.6rem;
                  color: #ff6600;
                  font-weight: bold;
                  margin-bottom: 0;
                "
              >
                Olá!
              </p>
              <p style="font-size: 1rem; color: #000">
                Têm uma nova ${docType} esperando por você lá na SociiLaw.<br />
                <b style="color: #ff6600">${creatorName}</b> criou uma nova
                <b style="color: #ff6600">${title}</b> e adicionou você como
                <b style="color: #ff6600">${type || name}</b>.<br />
              </p>
            </div>
            <img
              width="100%"
              style="border-radius: 30px; margin-top: 0.5rem; margin-bottom: 2rem"
              src="https://firebasestorage.googleapis.com/v0/b/appm2law.appspot.com/o/email_imgs%2Fshared-document-email.webp?alt=media&token=c0ccdf84-d302-43e3-9f1f-aaf8f88e7d79"
            />
            <div style="padding: 2rem; background-color: #fff; border-radius: 30px">
              <p style="color: #000; font-size: 1rem">
                Clique no botão abaixo e responda sua parte da ${docType}.
              </p>

              <div style="display: flex">
                <a
                  href="https://app.sociilaw.com/tag-email?email=${email}&type=automation-send&id=${id}&id2=${id2}"
                  target="_blink"
                  style="
                    background-color: #ff6600;
                    color: #fff;
                    border: none;
                    border-radius: 30px;
                    padding: 0.5rem 1rem;
                    font-size: 1.2rem;
                    margin: 1rem auto;
                    text-decoration: none;
                    cursor: pointer;
                  "
                >
                  Responder ${docType}
                </a>
              </div>

              <p style="color: #000; font-size: 1rem">
                Após todos os participantes finalizarem o preenchimento da
                ${docType}, o documento ficará disponível para todos.
              </p>
            </div>
          </section>
        </div>
      `,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function mailSignToken(email, token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: `Token: ${token}. Código para confirmar a assinatura na Socii`,
        html: `
        <div
          style="background-color: #ffeee6; font-family: 'Roboto Flex', sans-serif"
        >
          <section style="background-color: #ff6600; padding: 1rem; display: flex">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/appm2law.appspot.com/o/email_imgs%2Flogo.png?alt=media&token=70489f56-8205-408d-aebf-e8b1a542e88d"
              width="200px"
              style="margin: 0 auto"
            />
          </section>
          <section style="background-color: #ff9966; padding: 0.95rem 0">
            <p
              style="
                color: #fff;
                font-size: 1.8rem;
                text-align: center;
                margin: 0;
                font-family: 'Bodoni Moda', serif;
              "
            >
              ASSINATURA
            </p>
          </section>
          <section style="max-width: 600px; margin: 0 auto; padding: 2rem">
            <div style="padding: 0 1.5rem">
              <p
                style="
                  font-size: 1.6rem;
                  color: #ff6600;
                  font-weight: bold;
                  margin-bottom: 0;
                "
              >
                Token de verificação de assinatura
              </p>
              <p style="font-size: 1rem; color: #000">
                Utilize o token abaixo para confirmar sua assinatura. Por motivos de segurança, ele é válido apenas por 4 horas.
              </p>
            </div>
            <div style="padding: 1rem; background-color: #fff; border-radius: 30px">
              <p style="color: #000; font-size: 2rem; font-weight: bold; text-align: center;">
                ${token}
              </p>
            </div>
          </section>
        </div>
      `,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function mailNewClasses({ user, tasks, requestId, stepId, brandName }) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: "paulo@sociilaw.com, contato@sociilaw.com, gil@sociilaw.com, evillin@sociilaw.com, larissa.socii@gmail.com",
        subject: `Novas classes adicionados - ${brandName}`,
        html: `
          <p>Nome: <b>${user.displayName}</b></p>
          <p>E-mail: <b>${user.email}</b></p>
          <p>Nome da marca: <b>${brandName}</b></p>
          <p>Novas classes:</p>
          <ul>
            ${tasks.map(
              (t) =>
                `<li>${t.class.id} - <a href="https://lawyers-socii.web.app/cliente/${user.uid}/requisicao/${requestId}/etapa/${stepId}/tarefa/${t.taskId}">Link da tarefa</a></li>`
            )}
          </ul>
        `,
      }),
    };

    return fetch(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      requestOptions
    );
  }

  function requestAccountDeletion() {
    const currentUser = auth.currentUser;

    return axios.post(
      "https://us-central1-appm2law.cloudfunctions.net/mail-sendMail",
      {
        to: "paulo@sociilaw.com, fernanda@sociilaw.com, daniel@sociilaw.com",
        subject: `Solicitação de exclusão de conta - ${currentUser.email}`,
        html: `
          <p>Usuário: <b>${currentUser.displayName}</b></p>
          <p>E-mail: <b>${currentUser.email}</b></p>
          <p>UID: <b>${currentUser.uid}</b></p>
        `,
      }
    );
  }

  return (
    <FirebaseContext.Provider value={firebaseObj}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
