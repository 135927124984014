import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import DefaultButton from "../Buttons/Default";
import TutorialImg1 from "../../../../Images/tutorial-img1.svg";
import TutorialImg2 from "../../../../Images/tutorial-img2.svg";
import TutorialImg3 from "../../../../Images/tutorial-img3.svg";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTutorial } from "../../../../store/actions";
import SwipeableViews from "react-swipeable-views";
import { FirebaseContext } from "../../../../firebase";
import { useLocation } from "react-router-dom";
import { DOCUMENT_ALREADY_FINISHED, HOME } from "../../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 19,
    fontWeight: 500,
    color: "#707070",
  },
  paper: {
    padding: "2rem",
    maxWidth: 400,
    borderRadius: 29,
  },
  btnsContainer: {
    maxHeight: 220,
    overflowY: "auto",
  },
}));

const steps = [
  {
    text: "Seja bem vindo à Socii! Aqui você encontrará soluções empresariais descomplicadas e acessíveis!",
    img: TutorialImg1,
  },
  {
    text: "Aqui você tem acesso a serviços, automações e assinatura digital de documentos.",
    img: TutorialImg2,
  },
  {
    text: "Selecione um tutorial da lista a seguir, ou acesse a qualquer momento, em “Ajuda” no menu principal.",
  },
  {
    text: "Até que você realize sua primeira ação no app, aparecerão cards exemplo na Home e no Histórico.",
    img: TutorialImg3,
  },
];

export default function IntroPopup() {
  const classes = useStyles();
  const theme = useTheme();

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const tutorials = useSelector((state) => state.tutorials);
  const activeTutorial = useSelector((state) => state.activeTutorial);
  const activatedCodePopup = useSelector((state) => state.activatedCodePopup);
  const acceptTermsDialog = useSelector((state) => state.acceptTermsDialog);
  const user = useSelector((state) => state.user);

  const { api } = useContext(FirebaseContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTutorial) {
      setOpen(false);
    }
  }, [activeTutorial]);

  useEffect(() => {
    if (
      user.uid &&
      !user.isAnon &&
      !user.sawIntro &&
      !activeTutorial &&
      !activatedCodePopup.open &&
      !acceptTermsDialog &&
      location.pathname === HOME
    ) {
      setOpen(true);
    }
  }, [user, activeTutorial, activatedCodePopup, acceptTermsDialog]);

  const handleGoBack = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };
  const handleGoForward = () => {
    setActiveStep((previousStep) => previousStep + 1);
  };
  const handleClose = () => {
    setOpen(false);
    updateUserField();
  };
  const startTutorial = (type) => {
    const tutorial = tutorials.find((tut) => tut.type === type);
    dispatch(setActiveTutorial({ ...tutorial, activeIndex: 0 }));
  };

  const updateUserField = () => {
    return api.userSawIntro();
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {activeStep === 2 ? null : (
          <Grid item xs={12} container justifyContent="center">
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              disabled={true}
            >
              {steps.map((step, index) => (
                <Grid container justifyContent="center" key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img src={step.img} width="60%" alt="tutorial img" />
                  ) : null}
                </Grid>
              ))}
            </SwipeableViews>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography className={classes.text} align="center">
            {steps[activeStep].text}
          </Typography>
        </Grid>
        {activeStep === 2 ? (
          <Grid
            item
            xs={12}
            container
            spacing={1}
            className={`${classes.btnsContainer} scroll`}
          >
            {tutorials.map((tutorial) => (
              <Grid item xs={12} key={tutorial.type}>
                <DefaultButton
                  text={tutorial.text}
                  disabled={tutorial.disabled}
                  onClick={() => startTutorial(tutorial.type)}
                  variant="outlined"
                  color="primary"
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
        <Grid item xs={12} container spacing={2}>
          {activeStep === 0 ? null : (
            <Grid item xs={6}>
              <DefaultButton
                color="primary"
                variant="outlined"
                text="Voltar"
                onClick={handleGoBack}
              />
            </Grid>
          )}
          {activeStep === 3 ? (
            <Grid item xs={6}>
              <DefaultButton
                color="primary"
                variant="contained"
                text="Fechar"
                onClick={handleClose}
              />
            </Grid>
          ) : (
            <Grid item xs={activeStep === 0 ? 12 : 6}>
              <DefaultButton
                color="primary"
                variant="contained"
                text="Próximo"
                onClick={handleGoForward}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
